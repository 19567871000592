import React, {useEffect} from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import UserDataForm from '../../global-elements/userDataForm'
import ValidatorService from "../../../../services/validator";
import TC from "../../add-ons/TC";
import Esign from "../../add-ons/esign";
import Banner from "../../../body/banners/banner";
import '../../../../assets/terms/dm-terms.css';
import marketing from "../../../../services/marketing"
import Acknowledgements from "../../add-ons/Acknowledgements";
import CreditProtection from "../../add-ons/CreditProtection";
import Features from "../../global-elements/feature";
import Security from "../../global-elements/security";
import Reputation from "../../global-elements/reputation";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import Trustbox from "../../global-elements/trustbox";
import Recaptcha from "../../../body/forms/components/recaptcha/Recaptcha";
import InvalidStateModal from "../../global-elements/invalidStateModal";
import PixelsService from '../../../../services/pixels';
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import ConfirmSmsConsent from "../../global-elements/confirmsSmsConsent";
import SubmitApplicationService from "../../../../services/submitApplication";
import Econsent from '../../global-elements/eConsent';
import AdobeScript from "../../../../services/adobeScript";
import FeaturesService from "../../../../services/features";
import OverlimitPrivilege from "../../add-ons/OverlimitPrivilege";

function Landing() {
    const ada = new AudioEyeSupport();
    const Setting = useStoreState((state) => state.siteSettingsModel);
    const setGrandBanckDeclined = useStoreActions((actions) => actions.siteSettingsModel.setGrandBanckDeclined);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const alResult = useStoreState(state => state.alResult);
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const WaterfallEvents = { "DM" : 10, "PREQUAL" : 6, "CFC" : 18, "STANDALONE" : 24, "CS" : 29};
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const text = useStoreState((state) => state.CFCText.landing);
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const resultStep = useStoreActions(actions => actions.setDisplayResult);
    const history = useHistory();
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const [errorShow, setErrorShow] = useState(false);
    const wildcard = new Wildcard();
    const closeError = () => setErrorShow(false);
    const InvalidState = useStoreState((state) => state.invalidState);
    const setInvalidState = useStoreActions((actions) => actions.setInvalidState);
    const [errorMessage, setErrorMessage] = useState(false);
    const validatorService = new ValidatorService();
    const methods = useForm({mode: 'onSubmit'});
    const [isLoading, setLoading] = useState(false);
    const useRecaptcha = useStoreState((state) => state.siteSettingsModel.useRecaptcha);
    const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
    const [recaptchaDisabled, setRecaptchaDisabled] = useState(true);
    const utms = new marketing();
    const pendingInfo = ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];
    const setInitPixels = useStoreActions(actions => actions.setInitPixels);
    const initPixels = useStoreState(state => state.initPixels);
    const PixelService = new PixelsService();
    const submitApplicationService = SubmitApplicationService();
    const MainPath = useStoreState(state => state.siteSettingsModel.MainPath);
    const setUseOwnBanner = useStoreActions(actions => actions.siteSettingsModel.setUseOwnBanner);
    const adobe = new AdobeScript();
    const CookieConsentLoaded = useStoreState(state => state.siteSettingsModel.cookieConsentLoaded);
    const setCookieConsentLoaded = useStoreActions(actions => actions.siteSettingsModel.setCookieConsentLoaded);
    const [isHalfScrolled, setIsHalfScrolled] = useState(false);
    const features = new FeaturesService();

    useEffect(() => {
        if(!initPixels){
            utms.mapper();
            PixelService.initialize().pageView("/landing").event("google", "CFC", "Landing Page");
            ada.setup().setDYHeaders(process.env.REACT_APP_CFC).setDYContext({ type: "HOMEPAGE" }).setDYBanner(setUseOwnBanner);
            adobe.setup();
            setTimeout(() =>  adobe.startEvent('page_view'), 5000);
            setUseOwnBanner(true)
            utms.trackEvent(13, "CFC", "", Setting.productSettings.ProductId, "Flow");
            getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
            setInfo({ index : 'ProductCode', value : Setting.mainSettings.ProductCode })
            setInitPixels(true)
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        if(CookieConsentLoaded === false){
            setCookieConsentLoaded(true)
            setTimeout(() => ada.sendDYEvent(
            {
                name: "Application", 
                properties: { 
                    dyType : "add-to-cart-v1", 
                    productId : "Application",
                    value : 1,
                    quantity : 1,
                    applicationId : sessionStorage.getItem("oldSessionToken")
                }
            }), 5000)
        }
    })

    useEffect(() => {
        const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const fullHeight = document.body.scrollHeight;
        const scrolledToBottom = scrollPosition + windowHeight;

        if (scrolledToBottom >= fullHeight / 2) {
        console.log()
        if(isHalfScrolled === false){
            
            setIsHalfScrolled(true)
        }
        
        }
    };
    
    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHalfScrolled]);

    useEffect(() => {
    if (isHalfScrolled) {
        scrollEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHalfScrolled]);

    const scrollEvent = () => {
        adobe.event('scroll')
    };

    const submit = async (data) => {
        setLoading(true)
         //PATCH remeber to remove this
         if(data.State === 'WA'){
            setInvalidState(true); 
            setApplicationResult({result : "INVALIDSTATE"}) 
            return;
        }
        ///////////////////////////////////
        if (!validatorService.preValidations(data, methods, info)) {
            let result = await validatorService.addressValidate(info, methods, setInvalidState);
            if(result === "INVALIDSTATE") { 
                setInvalidState(true); 
                setApplicationResult({result : "INVALIDSTATE"}) 
                return;
            }
            if(result !== false){
                await submitApplication();
            }
        }
        setLoading(false)
    }

    async function submitApplication(){
    
       
        
        const response = await submitApplicationService.submitApplication(info);

        if (response) {
            ada.setDYContext( { type:"CART", data : ["Application"] }).sendDYEvent(
            {
                name: "Submission",   
                properties: {
                    uniqueTransactionId: response.ref_number, 
                    dyType : "purchase-v1", 
                    value : 1,
                    applicationId : sessionStorage.getItem("oldSessionToken"),
                    cart : [
                        {
                            productId : "Application",
                            quantity: 1,
                            itemPrice : 1.00
                        }
                    ]

                }
            });
            if (response.IsGrandbank === true) { setGrandBanckDeclined(true); }
      
            if (response.result != null) {
              if(response.result){     utms.updateSessionReport("appResult",   response.result.toUpperCase() ); }
              if(response.ref_number){ utms.updateSessionReport("applicantId", response.ref_number); }
             
              setInfo({ index: "step", value: 3 });
              setApplicationResult(response);
              resultStep(true);
              window.scrollTo(0, 0);

              //Remove this section when payment flow get modernized
              if(response.result.toUpperCase() === "PENDFEE" && Setting.brandSettings.ProductName.toLowerCase() === 'fit'){
                if(alResult === false){
                  PixelService.pageView("/approved").approvedCalls(response.ref_number)
                 
                  utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, response.ref_number, Setting.productSettings.ProductId, "Flow");
                  
                  setAlResult(true)
                }
                const url = "https://" + ((process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION") ? "test" : "") +"offer.your"+brandSettings.ProductName.toLowerCase()+"card.com/payment/?RefNo="+response.ref_number
                window.location.replace(url); 
                return;
              }
              // ---------------------------------------------------
              if (response.result.toUpperCase() === "APPROVED") {
                history.push(MainPath + "approved" + window.location.search);
                return;
              }

                if(features.isFeatureEnabled('FlinksActive')  && response.result.toUpperCase() === 'PENDUSER') {
                    history.push("/link-account"+window.location.search); return;
                }

              if (pendingInfo.includes(response.result.toUpperCase())) {
                history.push(MainPath + "pendinfo" + window.location.search);
                return;
              }
              history.push(MainPath+ "declined" + window.location.search);
              return;
            }
          } else {
            setErrorMessage("An unknown error has occured. Please try again.");
            setErrorShow(true);
            setLoading(false);
          }
          setErrorMessage("An unknown error has occured. Please try again.");
          setErrorShow(true);
          setLoading(false);
    }

    return (
        <>
            <Banner />
            <ErrorModal size="sm" show={errorShow} message={errorMessage} closeError={closeError}/>
            <InvalidStateModal visible={InvalidState} />
            <div className="container mt-5 mb-5">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(submit)}>
                        <div  className="dm-form">
                            <UserDataForm />
                        </div>
                        

                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">E-Sign Disclosure</h1>
                            </div>
                            <Esign />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">Pricing and Terms</h1>
                            </div>
                            <div className="col-12 ">
                                {/* TODO: UNCOMMENT THIS WHEN WE HAVE SAMPLES!!!
                                <TC data={{productCode: info.ProductCode}} /> */}
                                <div className="terms-wrapper">
                                    <TC data={{ productCode: Setting.mainSettings.ProductCode}} />
                                </div>                                
                            </div>                            
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">Credit Protection</h1>
                            </div>
                            <CreditProtection />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">Overlimit Privilege</h1>
                            </div>
                            <OverlimitPrivilege />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="terms-header-title">Acknowledgements</h1>
                            </div>
                            <Econsent />
                            <Acknowledgements data={{productCode: Setting.mainSettings.ProductCode}} />
                           
                        </div>
                        {Setting.smsConcentModuleCFC === true && (
                            <div className="row mt-4 pr-0 pl-3">
                                <div className="col-12">
                                    <ConfirmSmsConsent></ConfirmSmsConsent>
                                </div>
                            </div>
                        )}
                        <div className="row ">
                            <div className="captcha-container">
                                {useRecaptcha && Setting.productSettings.CaptchaKey !== null ? (
                                    <div className="form-group displayFlexCenter">
                                    <Recaptcha
                                        setRecaptchaDisabled={setRecaptchaDisabled}
                                        ProductName={brandSettings.ProductName}
                                    />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <button 
                                onClick={() => {
                                    PixelService.event("google", "CFC", "Submit Application");
                                    utms.trackEvent(15, "CFC", "", Setting.productSettings.ProductId, "Flow");
                                    adobe.event('apply_now_click', Setting.CardColors.find(value => value.Code === info.cardColorCode))
                                }}
                                disabled={isLoading || ((useRecaptcha && Setting.productSettings.CaptchaKey !== null) &&recaptchaDisabled)} id="dm-submit-app" style={{backgroundColor : Setting.brandSettings.Tertiary, width : "22%"}} className="btn text-small mt-3 rounded-pill" type="submit">
                                {isLoading ? 'Submitting...' : 'Submit Application'}
                                </button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div className="row pt-1 pb-1" id="header-text-container" style={{ backgroundColor:  Setting.brandSettings.Secondary }}>
                <div className="col-12 text-center">
                    <h1 id="offer-header-text" className="">{wildcard.update().proccess(text.block1.title)}</h1>
                </div>
            </div>
            <div className="container mb-3">
                <div className="row mb-3">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="card-image-container text-center pt-3">
                            <img alt="" src={Setting.brandSettings.CardImage} className="col-lg-12" />
                        </div>
                        
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                        <Features />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <Security />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <Reputation />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Trustbox />
                    </div>
                </div>
            </div>
           
        </>
        
    );
}

export default Landing;
