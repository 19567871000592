import React from 'react';
import { useStoreState } from 'easy-peasy';


function PaymentChoice(props) {
    const setting = useStoreState(state => state.siteSettingsModel);

    return (
        
        <div className='row mb-0'>
            <div className='col-12 '>
                <h1 className='titles-dm'><strong>Are you ready to make your proccessing fee payment online now?</strong></h1>
            </div>
            <div className='col-6'>
            <button onClick={() => {props.setChoice("yes")}} className='btn btn-round btn-sm btn-block tempClass'  style={{backgroundColor : setting.brandSettings.FooterBgColor , fontWeight : "700"}}>Yes</button>


            </div>
            <div className='col-6'>
            <button onClick={() => {props.setChoice("no")}} className='btn btn-round btn-sm btn-block tempClass'  style={{backgroundColor : setting.brandSettings.FooterBgColor , fontWeight : "700"}}>No</button>

            </div>
        </div>
       
    );

}

export default PaymentChoice;