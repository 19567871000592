import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import './../../../assets/layout/abandon.css';
import { useFormContext } from 'react-hook-form';

const OverlimitPrivilege = () => {
    const Setting = useStoreState(state => state.siteSettingsModel);
    const info = useStoreState(state => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const { register } = useFormContext();  

    return (
        <div className='col-12 mt-3 mb-3 terms-acknowledgements'>
            <div className="form-check">
                <input 
                    checked={info.overlimitPrivilege} 
                    onClick={(e) => setInfo({ index: "overlimitPrivilege", value: info.overlimitPrivilege === true ? false : true, })} 
                    className="form-check-input" type="checkbox" value={info.overlimitPrivilege}  id="overlimitPrivilege" {...register("overlimitPrivilege")} 
                />
                <label className="form-check-label">
                    <b>Yes,</b> I want to enroll in optional Overlimit Privilege.<br></br>
                    <br></br>
                    <b>This allows you to make necessary purchases even when they exceed your credit limit, avoiding the embarrassment of inconvenience of declined transactions.</b><br></br>
                    <br></br>
                    By clicking this box, I confirm that I have read and agree to the <a  href={Setting.webAssetsBaseUrlSettings.Cdn + "pdf/overlimit-privilege/overlimit_privilege_terms.pdf"} rel="noopener noreferrer" target="_blank" >Overlimit Privilege Terms and Conditions</a>. 
                    I understand that if I go over my credit limit,<br></br>
                    I will be charged a fee of up to $41. I also understand that I may cancel the Overlimit Privilege program at any time.<br></br>
                </label>
            </div>
        </div>
    );
};

export default OverlimitPrivilege;
