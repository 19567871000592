import React from 'react';
import { useStoreState } from 'easy-peasy';


function PaymentAccepted(props) {
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const setting = useStoreState(state => state.siteSettingsModel);
    const ProductName = setting.brandSettings.ProductName === "Fit" ? setting.brandSettings.ProductName.toUpperCase() : setting.brandSettings.ProductName;

    return (
        
        <div className='col-12 p-0'>
            <div className='card gbCard whiteBackground' >
                <div className='card-body no-bottom-margin  row' style={{"marginBottom" : "0 !important", }}>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-12 mb-4'>
                                <h1 className='text-center' style={{color : "black"}}><strong>Payment Accepted!</strong></h1>
                            </div>
                            <div className='col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 col-xs-12'>
                                <h3>Your payment of <span style={{color: "#e9b465"}}>${applicationResult.security_deposit}</span></h3>
                                <p className='p-0'>
                                    for the Processing Fee (one-time) was accepted. You may have already
                                    received your {ProductName} card. If not; it is already on its way. Call us to activate
                                    your {ProductName} card on or after DATE
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );

}

export default PaymentAccepted;