import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Modal } from 'react-bootstrap';
import './../../../assets/layout/abandon.css';
import { useFormContext } from 'react-hook-form';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EConsent = (props) => {
    const Setting = useStoreState(state => state.siteSettingsModel);
    const info = useStoreState(state => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const { register } = useFormContext();   
    const [visible, setVisible] = useState(false);

    const confirm = () => {
        setInfo({index : 'eConsentAcknowledgement', value :  true})
        setVisible(false)
    }

    return (
        <>
        <div className='col-12 mt-3 mb-3 terms-acknowledgements'>
            <div className="form-check">
                <input 
                    checked={info.eConsentAcknowledgement } value={info.eConsentAcknowledgement}
                    onClick={(value) => { 
                      if(info.eConsentAcknowledgement === false){setVisible(true)};
                        
                    }} 
                    className="form-check-input" 
                    type="checkbox" id="eConsentAcknowledgement" {...register("eConsentAcknowledgement")} 
                />
                <label className="form-check-label">
                    By clicking this box, I confirm that I have read and consent to the <a  href={Setting.webAssetsBaseUrlSettings.Cdn + "pdf/terms/AcknowledgementsEConsentCopy.pdf"} rel="noopener noreferrer" target="_blank" >Paperless Terms and Conditions</a> and agree to receive paperless billing statements. 
                    My consent is not required for approval of this application. I understand that I can change the paperless statement settings at anytime once my account has been established.
                </label>
            </div>
            
        </div>
        <Modal show={visible} backdrop="static" id="e-consent-modal" onBackdropClick={() => setVisible(false)}  style={{top : "3%"}}>
            <Modal.Body className='modal-body'>
                <div className='modal-body-text-container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h4 style={{marginRight : "12%", fontWeight: 'bold', wordBreak: 'nowrap', fontSize: '14pt'}}>Paperless Statements Terms and Conditions <span style={{cursor : 'pointer', color : 'gray'}} className='clickToClose' onClick={ () => {setVisible(false)} }><FontAwesomeIcon icon={faTimes} /></span></h4>
                            <p className='text-left'><a target='_blank' style={{color : "black", textDecoration : "underline"}}  rel="noopener noreferrer" href={Setting.webAssetsBaseUrlSettings.Cdn + "pdf/terms/AcknowledgementsEConsentCopy.pdf"}>Save/Print a Copy</a></p> 
                        </div>
                        <div className='col-12' style={{overflowY : "scroll", maxHeight: "57vh"}}>
                           <p className='text-left'>
                                By accepting these Paperless Statements Terms and Conditions, you affirmatively consent to receive your periodic billing statements electronically (“e-Statements”) 
                                through the online service that you must register for, either on the online portal or by downloading the Mobile App (the “Paperless Statement program”). 
                                The delivery of e-Statements will replace the delivery of such statements by paper through the U.S. postal mail.
                            </p>
                            <p className='text-left'>
                                Before accepting these Paperless Statements Terms and Conditions, you must have consented to receive certain notices and disclosures relating to your accounts 
                                electronically rather than in paper form. Please review the terms of the <a href={Setting.webAssetsBaseUrlSettings.Cdn + "pdf/terms/EConsent.pdf"} target="_blank" rel="noopener noreferrer">Electronic Communications Disclosure and Consent</a> (the “Disclosure and Consent”) 
                                and keep a copy for your records.
                            </p>
                            <p className='text-left'>
                                Your consent to this Paperless Statements Terms and Conditions covers the periodic statements you are provided in connection with your Account. 
                                For Accounts with authorized users, only the primary cardholder’s authorization is required to enroll in the Paperless Statement program. Your choice to go paperless 
                                binds any and all authorized users on the Account(s). Your consent will be considered your confirmation that you have received, understand this Paperless 
                                Statements Terms and Conditions and you agree to be bound by the provisions herein.
                            </p>
                            <p className='text-left'>
                                Each month, we will send an automated notification email to the email address designated by you informing you that your e-Statement is ready for viewing. This email will be 
                                sent shortly after the end of your statement period and will provide instructions on how to access your e-Statement. The email will also be the only notice you receive of the 
                                availability of your e-Statement online. You certify any email address you provide is a valid email address for the purposes described in this Paperless Statements 
                                Terms and Conditions. You agree to maintain your designated email address until you provide us with a new one by contacting customer service or through your online account. 
                                If you allow someone else to access your designated email address, you accept the risk that they may see your sensitive information.<br></br>
                                You may withdraw your consent to receive e-Statements at any time by changing your delivery preferences within the online service, or via the Mobile App. 
                                If you withdraw your consent, you will receive paper versions of the statements associated with your account at the physical address you provided through the U.S. 
                                postal mail.<br></br>
                                You can request paper copies of e-Statements by contacting customer service and, when available, you can access a printable version of these e-Statements through 
                                your online account or Mobile App.
                            </p>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <button  onClick={ () => {confirm()} } style={{backgroundColor : "rgb(241, 99, 36)", paddingLeft : "8%", paddingRight : "8%", maxWidth: "50%"}} className='mt-4 btn btn-secondary mt-3 mx-auto'>I Confirm</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default EConsent;
