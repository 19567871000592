import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import PaymentChoice from './paymentChoice';
import CallCenter from './callCenter';
import OtherPaymentOptions from './otherPaymentOptions';
import PaymentOptions from './paymentOptions';
import PaymentAccepted from './paymentAccepted';


function EntryPoint() {
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const setting = useStoreState(state => state.siteSettingsModel);
    const [choice, setChoice] = useState(false)

    return (
        <div className='col-12 mt-3'>
            <div className='row mb-3'>
                <div className='col-12 '><h1 className='paymentInfo'><strong>Approved Applicant:</strong> {applicationResult.full_name}</h1></div>
                <div className='col-12 '><h1 className='paymentInfo'><strong>Reference Number:</strong> {applicationResult.ref_number}</h1></div>
                <div className='col-12 '><h1 className='paymentInfo'><strong>Processing Fee:</strong> {applicationResult.security_deposit}</h1></div>
            </div>
            { choice === false && (<PaymentChoice setChoice={setChoice} />) }
            { choice === "Accepted" && (<PaymentAccepted setChoice={setChoice} />) }
            { choice === "no"  && (<> <CallCenter /> <OtherPaymentOptions /> </>) }
            { choice === "yes" && (<PaymentOptions setChoice={setChoice} />) }
            { (choice !== false && choice !== "Accepted") && (
            <div className='col-12'>
                <div className='row mb-0 mt-4'>
                    <div className='col-lg-4 offset-lg-4 col-md-4 offset-md-4 col-sm-12 col-xs-12 '>
                        <button onClick={() => setChoice(false)} className='btn btn-block' style={{backgroundColor : setting.brandSettings.BannerBgColor}}>Back</button>
                    </div>
                </div>
            </div>
            )}
        </div>
    );

}

export default EntryPoint;