import React,{ useRef, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import DebitCard from './debitCard';
import CheckingAccount from './checkingAccount';
import AxiosWrapper from '../../../../services/axios';

function PaymentOptions(props) {
    const setting = useStoreState(state => state.siteSettingsModel);

    const creditCard = useRef(null);
    const account = useRef(null);
    const client = new AxiosWrapper();
    useEffect(() => {
        const handleClassChange = () => {
        const element = creditCard.current;
        if (element) {
            const isActive = element.classList.contains('active');
            element.style.backgroundColor = isActive ? setting.brandSettings.BannerBgColor : "#fff";
            element.style.color = isActive ? 'white' : "black";
            element.style.boxShadow = "black 0px 1px 10.1px";
        }
        };
        
        handleClassChange();
        const observer = new MutationObserver(handleClassChange);
        observer.observe(creditCard.current, { attributes: true });
        return () => {
        observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleClassChange = () => {
        const element = account.current;
        if (element) {
            const isActive = element.classList.contains('active');
            element.style.backgroundColor = isActive ? setting.brandSettings.BannerBgColor : "#fff";
            element.style.color = isActive ? 'white' : "black";
            element.style.boxShadow = "black 0px 1px 10.1px";
        }
        };

        handleClassChange();
        const observer = new MutationObserver(handleClassChange);
        observer.observe(account.current, { attributes: true });
        return () => {
        observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='col-12 mt-3'>
            <Tab.Container id="left-tabs-example"  justify>
                <Row className='m-0'>
                    <Col className='p-0'>
                        <Nav bsStyle={client.isMobile() ? "pills" : "tabs"} variant={client.isMobile() ? "pills" : "tabs"}  justify>
                            <div className={(client.isMobile() ? "col-12" : "col") + " p-0 "}>
                                <Nav.Item>
                                    <Nav.Link ref={creditCard} className='btn rounded-top pr-1 pl-1' style={{borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"}} eventKey="first">Debit Card</Nav.Link>
                                </Nav.Item>
                            </div >
                            <div className={(client.isMobile() ? "col-12" : "col") + " p-0 "}>
                                <Nav.Item>
                                    <Nav.Link ref={account} className={(client.isMobile() ? 'btn rounded-bottom pr-1 pl-1' : 'btn rounded-top pr-1 pl-1')}    style={{borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"}} eventKey="second">Checking or Savings Account</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Col>
                   
                </Row>
                <Row className='m-0'>
                    <Col className='p-0' >
                        <Tab.Content>
                            <Tab.Pane eventKey="first"><DebitCard setChoice={props.setChoice} /></Tab.Pane>
                            <Tab.Pane eventKey="second"><CheckingAccount setChoice={props.setChoice} /></Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );

}

export default PaymentOptions;